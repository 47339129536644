<template>
    <section class="h-100">
        <div class="row mx-0" :style="`height:calc(100vh - 246px)`">
            <div class="col px-0 h-100">
                <div ref="cont1" class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100% - 10px);">
                    <div class="row mx-0 justify-center">
                        <card-producto
                        v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                        :info="prod"
                        :diseno="prod.data_promocion"
                        class="cr-pointer card-prod"
                        />
                    </div>

                    <contentLoader v-show="loading" />

                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    components: {
        cardProducto: () => import('~/pages/almacen/pedidos/partials/card-producto-pedido2'),
    },
    data(){
        return {
            editar: false,
            confirmar_pedido:false,
            loading:false,
            cargando:false,
            id_eliminar:null
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            productos: 'pedidos/pedidos_admin/productos',
            pedido: 'pedidos/pedidos_admin/pedido',

        }),
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    watch:{
        id_pedido(val){
            if(val){
                this.listar_productos()
            }else{
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
                this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
                this.$store.commit('pedidos/pedidos_admin/set_productos', [])
            }
        }
    },
    destroyed(){
        this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
        this.$store.commit('pedidos/pedidos_admin/set_productos', [])
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                await this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', this.id_pedido)
                // console.log('aca');
                if(this.pedido.pago_estado === 2){
                    this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
                }
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
.trans-card:first-child{
    // background-color: #0d4dff20;
    animation-duration: 4s;
    animation-iteration-count: 1;
    animation-name: slidein;
}
.br-12{
    border-radius: 12px !important;
}
</style>
